import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDoctor } from '../../../interfaces/doctor';

import { useDispatch } from 'react-redux';
import { getAllDoctorURL } from '../../../constants/url';
import { clearLocalStorage, logout } from '../../../redux/auth/action';
import DoctorForm from './AddDoctor/DoctorForm';
import './Doctors.scss';
import { deleteData } from './api';

type DoctorsProps = {
  doctors: IDoctor[];
  onEditDoctor?: (doctor: IDoctor) => void;
  onDeleteDoctor?: (id: string) => void;
};

const DoctorsTable = ({
  doctors,
  onEditDoctor = () => null,
  onDeleteDoctor = () => null
}: DoctorsProps) => {
  const navigate = useNavigate();

  const [doctor, setDoctor] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleShowInfo = (id) => navigate(`/doctor-profile/${id}`);
  const handleEditDoctor = (editedDoctor: IDoctor) => {
    setDoctor(editedDoctor);
    setVisibility(true);
    // console.log('editedDoctor :>> ', editedDoctor);
  };
  let dispatch = useDispatch();
  const handleDeleteDoctor = async (id: string) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      await deleteData(getAllDoctorURL + `/${id}`, { headers });
      onDeleteDoctor(id);
      message.success('Doctor deleted successfully');
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error('Token Expired: Login Again');
        dispatch(logout());
        clearLocalStorage();
        return;
      }
      message.error('Token Expired: Login Again');
      message.error('Failed to delete Doctor. Please try again.');
    }
  };

  const actions = (currentDoctor: IDoctor) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={() => handleShowInfo(currentDoctor._id)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={() => handleEditDoctor(currentDoctor)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteDoctor(currentDoctor._id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );
  // const uniqueCategoryTitles = Array.from(
  //   new Set(doctors.map((form) => form.category?.title || '--'))
  // );
  const columns: ColumnProps<IDoctor>[] = [
    {
      key: 'license',
      dataIndex: 'license',
      title: 'Licence Number',
      render: (license) => <span>{license?.number}</span>
    },
    {
      key: 'fullName',
      dataIndex: 'fullName',
      title: 'Name',
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (fullName) => <span>{fullName ? fullName : '--'}</span>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      render: (email) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {email}
        </span>
      )
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: 'Number',
      render: (phoneNumber) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#a5a5a5' }}>
          {phoneNumber}
        </span>
      )
    },
    // {
    //   key: 'category',
    //   dataIndex: 'category',
    //   align: 'center',
    //   title: 'Group',
    //   filters: uniqueCategoryTitles.map((title) => ({ text: title, value: title })),
    //   onFilter: (value, record) => record.category?.title === (value === '--' ? undefined : value),
    //   render: (category) => <strong>{!category ? '--' : category?.title}</strong>
    // },
    {
      key: 'accountStatus',
      align: 'center',
      dataIndex: 'accountStatus',
      title: 'Account Status',
      render: (accountStatus) => (
        <Tag
          style={{ borderRadius: 20 }}
          color={
            accountStatus === 'ACTIVE'
              ? '#5F8770'
              : accountStatus === 'PENDING'
              ? '#F6D876'
              : accountStatus === 'SHOULD_CHANGE_PASSWORD'
              ? 'custom_blue'
              : accountStatus === 'REJECT'
              ? '#df0c20'
              : '#F6D876'
          }
        >
          {accountStatus === 'ACTIVE'
            ? 'Active'
            : accountStatus === 'PENDING'
            ? 'Pending'
            : accountStatus === 'SHOULD_CHANGE_PASSWORD'
            ? 'Approved'
            : accountStatus === 'REJECT'
            ? 'Reject'
            : 'Pending'}
        </Tag>
      ),
      sorter: (a, b) => (a.accountStatus > b.accountStatus ? 1 : -1)
    },
    // {
    //   key: 'resendInvitation',
    //   dataIndex: 'resendInvitation',
    //   title: 'Resend Invitation',
    //   align: 'center',
    //   render: (id) => (
    //     <Button className='nowrap' type='primary'>
    //       Invite
    //     </Button>
    //   )
    // },
    {
      key: 'actions',
      align: 'center',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = doctors.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={doctors}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Edit Doctor</h3>}
        maskClosable={false} // Prevent closing modal by clicking outside
        closeIcon={null} // Remove close button from the modal header
      >
        <DoctorForm
          submitText='Update Doctor'
          onCancel={closeModal}
          onSubmit={onEditDoctor}
          doctor={doctor}
        />
      </Modal>
    </>
  );
};

export default DoctorsTable;
