import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Switch, message } from 'antd';
import PublicLayout from '../../layout/public/Public';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ImageLoader from '../../layout/components/patients/ImageLoader';
import './sessions.scss';
import { registerDoctorUrl } from '../../constants/url';

const SignUpSchemaStep1 = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, and one number.'
    )
});

const SignUpSchemaStep2 = Yup.object().shape({
  licenseNo: Yup.string(),
  hospitalName: Yup.string(),
  phoneNumber: Yup.number().required('Phone number is required')
});

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [switchState, setSwitchState] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/register-login-bg.png`}>
      <h4 className='mt-0 mb-1'>Registration</h4>
      <p className='text-color-200'>
        Step {currentStep}: Enter your {currentStep === 1 ? 'personal' : 'professional'} information
        to register your Account.
      </p>

      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          licenseNo: '',
          hospitalName: '',
          phoneNumber: '',
          img: null
        }}
        validationSchema={currentStep === 1 ? SignUpSchemaStep1 : SignUpSchemaStep2}
        onSubmit={(values) => {
          if (currentStep === 1) {
            setFormData((prevData) => ({ ...prevData, ...values }));
            handleNext();
          } else {
            setLoading(true);
            setFormData((prevData) => ({ ...prevData, ...values }));

            const requestData = new FormData();
            requestData.append('fullName', values.name);
            requestData.append('email', values.email);
            requestData.append('phoneNumber', values.phoneNumber);
            requestData.append('password', values.password);
            requestData.append('license[number]', values.licenseNo);
            requestData.append('defaultCategory', 'true');

            if (values.img instanceof File) {
              requestData.append('media', values.img);
            }

            fetch(registerDoctorUrl, {
              method: 'POST',
              body: requestData
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                  // console.log('Success:', data?.data?.doctor);
                  message.success('Submitted successfully');
                  navigate('/thank-you');
                  setLoading(false);
                }
                if (data.status === 400) {
                  setLoading(false);
                  message.error(data.errors[0].message);
                }
              })
              .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                message.error(error);
              });
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form className='mb-4 form_div' onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <>
                {/* Step 1 fields */}
                <Field name='name' disabled={loading}>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input {...field} placeholder='Name' />
                      <ErrorMessage name='name'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <Field name='email' disabled={loading}>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Email <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input {...field} placeholder='Email' />
                      <ErrorMessage name='email'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <Field name='password' disabled={loading}>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Password <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input.Password {...field} placeholder='Password' />
                      <ErrorMessage name='password'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <div className='d-flex align-items-center mb-4'>
                  <Switch
                    className={`${switchState ? 'active_toggle_color' : 'Inactive_toggle_color'}`}
                    checked={switchState}
                    onChange={(checked) => {
                      setSwitchState(checked);
                    }}
                    disabled={loading}
                  />
                  <span className='ml-2'>
                    I agree to the{' '}
                    <Link to='/terms' className='link_font'>
                      Terms and Privacy.
                    </Link>
                  </span>
                </div>
              </>
            )}

            {currentStep === 2 && (
              <>
                {/* Step 2 fields */}
                <div className='form-group'>
                  {/* <ImageLoader src={values.img as string} /> */}
                  <ImageLoader
                    src={values.img ? URL.createObjectURL(values.img) : undefined}
                    onSelectImage={(file) => setFieldValue('img', file)}
                  />
                </div>
                <Field name='phoneNumber' disabled={loading}>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Phone no. <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input {...field} placeholder='Enter your phone number' />
                    </div>
                  )}
                </Field>
                <Field name='licenseNo' disabled={loading}>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>License No.</label>
                      <Input {...field} placeholder='Enter your License number' />
                    </div>
                  )}
                </Field>
                <Field name='hospitalName' disabled={loading}>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>Hospital Name</label>
                      <Input {...field} placeholder='Hospital Name' />
                    </div>
                  )}
                </Field>
                {/* <Field name='state'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>State</label>
                      <Input {...field} placeholder='State' />
                    </div>
                  )}
                </Field>
                <Field name='address'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>Address</label>
                      <Input {...field} placeholder='Address' />
                    </div>
                  )}
                </Field>
                <Field name='city'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>City</label>
                      <Input {...field} placeholder='City' />
                    </div>
                  )}
                </Field> */}
              </>
            )}

            <div
              className='d-flex'
              style={{
                justifyContent: currentStep === 2 ? 'space-between' : ''
              }}
            >
              {currentStep === 2 && (
                <Button
                  type='primary'
                  htmlType='button'
                  className='active_toggle_color'
                  onClick={handlePrevious}
                  style={{
                    width: currentStep === 2 ? '49%' : '100%',
                    marginTop: '1rem'
                  }}
                  disabled={loading}
                >
                  Back
                </Button>
              )}
              <Button
                type='primary'
                htmlType='submit'
                className='active_toggle_color'
                style={{
                  width: currentStep === 2 ? '49%' : '100%',
                  marginTop: '1rem'
                }}
                disabled={!switchState}
                loading={loading}
              >
                {currentStep === 1 ? 'Next' : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {currentStep === 1 && (
        <p>
          Have an account?{' '}
          <Link to='../sign-in' className='link_font'>
            Sign in!
          </Link>
        </p>
      )}
    </PublicLayout>
  );
};

export default SignUp;
