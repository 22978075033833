import { Button, Input, message, Select, Spin, Dropdown, Space, Typography, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { getAllDoctorURL } from '../../../../constants/url';
import { useFormGroup } from '../../../../hooks/useFormGroup';
import { IDoctor } from '../../../../interfaces/doctor';
import { editDoctor } from '../../../../redux/doctors/actions';
import { hasErrorFactory } from '../../../../utils/hasError';
import { addData, updateData } from '../api';
import { getOrganBySpecie } from '../../form/api';
import { useSpecies } from '../../../../hooks/useSpecie';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllDiseaseURL, getOrganBySpecieURL } from '../../../../constants/url';
import { FormGroupSelectData } from '../../../../interfaces/selectedGroup';

const { Option } = Select;

// const ACCOUNT_STATUS = ['ACTIVE', 'PENDING', 'SHOULD_CHANGE_PASSWORD'];

const items: MenuProps['items'] = [
  {
    key: '0',
    label: 'ACTIVE'
  },
  {
    key: '1',
    label: 'PENDING'
  },
  {
    key: '2',
    label: 'SHOULD_CHANGE_PASSWORD'
  },
  {
    key: '3',
    label: 'REJECT'
  }
];

type Props = {
  onSubmit: (Doctor: IDoctor) => void;
  onCancel: () => void;
  doctor?: IDoctor;
  submitText?: string;
};

const defaultSubmitText = 'Add Doctor';
const emptyDoctor: IDoctor = {
  license: {
    number: ''
  },
  fullName: '',
  email: '',
  phoneNumber: '',
  _id: '',
  patients: [],
  category: null,
  defaultCategory: true,
  accountStatus: '' as string
};

const DoctorScheme = Yup.object({
  license: Yup.mixed().required(),
  fullName: Yup.string().required(),
  email: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  category: Yup.mixed().notRequired(),
  defaultCategory: Yup.string().notRequired()
});

const DoctorForm = ({
  submitText = defaultSubmitText,
  doctor = emptyDoctor,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(doctor.accountStatus || 'PENDING');

  const {
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    setValues,
    handleBlur,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IDoctor>({
    validationSchema: DoctorScheme,
    initialValues: doctor,
    onSubmit: async (values: any) => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`
        };
        let { accountStatus, patients, _id, ...updatedDoctor } = values;
        const payload = { ...updatedDoctor, accountStatus: accountStatus };
        if (submitText === 'Update Doctor') {
          let res = await updateData(getAllDoctorURL + `/${values._id}`, payload, {
            headers
          });
          editDoctor(res?.data?.data);

          if (res?.status === 201) {
            message.success(`${res?.data?.message}`);
          }

          onSubmit(res?.data?.data);
        } else {
          let res = await addData(getAllDoctorURL, updatedDoctor, { headers });
          const successMessage = res?.data?.message || 'Doctor Added Successfully';
          message.success(successMessage);
          onSubmit(res?.data?.data?.doctor);
        }

        onCancel();
      } catch (error) {
        const errorMessage =
          (error as any)?.response?.data?.errors[0].message || 'Validation Error';
        message.error(errorMessage);
        console.error('Submission Error:', error);
      } finally {
        setLoading(false);
      }
    }
  });

  const hasError = hasErrorFactory(touched, errors);
  const { formGroups } = useFormGroup();

  const handleCancel = () => {
    setValues(doctor);
    onCancel();
  };
  useEffect(() => {
    setValues(doctor);
  }, [doctor]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const selectedLabel = items[e.key]?.label;
    setSelectedStatus(selectedLabel || '');
    setFieldValue('accountStatus', selectedLabel);
  };
  // const defaultOption: any = { _id: null, title: 'Default' };

  // const formGroupsWithDefault = useMemo(() => {
  //   const updatedFormGroups = [...formGroups];
  //  // updatedFormGroups.unshift(defaultOption);

  //   return updatedFormGroups;
  // }, [formGroups]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <label htmlFor='doctor-name' style={{ fontWeight: 'bold' }}>
                  <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>
                  Name:
                </label>
                <Input
                  id='doctor-name'
                  placeholder='Name'
                  name='fullName'
                  type='text'
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue('fullName', value.target.value)}
                  value={values.fullName}
                  className={hasError('fullName')}
                />
              </div>
            </div>

            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <label htmlFor='doctor-number' style={{ fontWeight: 'bold' }}>
                  <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>
                  Phone Number:
                </label>
                <Input
                  id='doctor-number'
                  placeholder='Phone Number'
                  name='phoneNumber'
                  type='number'
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (/^\d+$/.test(inputValue) || inputValue === '') {
                      setFieldValue('phoneNumber', inputValue);
                    }
                  }}
                  value={values.phoneNumber}
                  className={hasError('phoneNumber')}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <label htmlFor='doctor-license' style={{ fontWeight: 'bold' }}>
                  <i
                    className='icofont-star-alt-2'
                    style={{ color: 'red', fontSize: '10px', visibility: 'hidden' }}
                  ></i>
                  License:
                </label>
                <Input
                  id='doctor-license'
                  placeholder='License'
                  name='license'
                  type='number'
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (/^\d+$/.test(inputValue) || inputValue === '') {
                      setFieldValue('license.number', inputValue);
                    }
                  }}
                  value={values.license?.number}
                  className={hasError('license')}
                />
              </div>
            </div>

            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <label htmlFor='doctor-email' style={{ fontWeight: 'bold' }}>
                  <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                  Email:
                </label>
                <Input
                  id='doctor-email'
                  disabled={submitText === 'Update Doctor'}
                  name='email'
                  type='email'
                  placeholder='email'
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue('email', value.target.value)}
                  value={values.email}
                  className={hasError('email')}
                />
              </div>
            </div>

            {submitText === 'Update Doctor' && (
              <div className='col-sm-12 col-12'>
                <div className='form-group'>
                  <label htmlFor='account-status' style={{ fontWeight: 'bold', display: 'block' }}>
                    <i
                      className='icofont-star-alt-2'
                      style={{ color: 'red', fontSize: '10px' }}
                    ></i>{' '}
                    Account Status:
                  </label>
                  <Dropdown
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: [selectedStatus],
                      onClick: handleMenuClick
                    }}
                    placement='bottom'
                    arrow={{ pointAtCenter: true }}
                  >
                    <Typography.Link>
                      <Space>
                        <span className='custom_dropdown'>
                          {selectedStatus}

                          <DownOutlined />
                        </span>
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='input-group mb-3'>
                {
                  <input
                    id='defaultCategory'
                    type='checkbox'
                    checked={values.defaultCategory}
                    onChange={(event) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      event.target.checked ? setFieldValue('category', null) : '';
                      setFieldValue('defaultCategory', event.target.checked);
                    }}
                  />
                }
                <label htmlFor='defaultCategory' style={{ fontWeight: 'bold' }}>
                  &nbsp;Default Form Group
                </label>
              </div>
            </div>
          </div>
          {!values.defaultCategory && (
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <label htmlFor='doctor-formGroup' style={{ fontWeight: 'bold' }}>
                    Form Group:
                  </label>
                  <Select
                    id='doctor-formGroup'
                    mode='multiple'
                    placeholder='Select Form Group'
                    value={
                      values.category
                        ? values.category?.map((cat: FormGroupSelectData) => cat._id)
                        : []
                    }
                    onChange={(selectedCategories: string[]) => {
                      setFieldValue(
                        'category',
                        selectedCategories.map((catId) => ({ _id: catId }))
                      );
                    }}
                    className={values.category ? '' : hasError('category')}
                    onBlur={() => setFieldTouched('category')}
                  >
                    {formGroups.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button disabled={!isValid || loading} type='primary' htmlType='submit'>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default DoctorForm;
