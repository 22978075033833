import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, message } from 'antd';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { getAllDoctorURL, imguploaderUrl, updateDoctorProfileURL } from '../../constants/url';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import { clearLocalStorage, logout } from '../../redux/auth/action';
import { updateData } from '../dashboards/doctors/api';
import EditAccountPage from '../services/EditAccounPage';

import { useParams } from 'react-router-dom';
import ImageLoader from '../../layout/components/patients/ImageLoader';

const FormItem = Form.Item;
const DoctorProfilePage = () => {
  const [doctorProfile, setDoctorProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageData, setPageData] = useState<IPageData>({ title: 'Doctor Profile', fulFilled: true });
  const role = localStorage.getItem('role');
  const doctor_id = localStorage.getItem('doctor_id');

  let dispatch = useDispatch();
  const { id } = useParams();
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.get(`${getAllDoctorURL}/${role === 'admin' ? id : doctor_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPageData((prevData) => ({
        ...prevData,
        title: response.data.data?.fullName || prevData.title
      }));
      setDoctorProfile(response?.data.data);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error('Token Expired: Login Again');
        dispatch(logout());
        clearLocalStorage();
        return;
      }
      message.error('Error fetching Doctor Profile');
    } finally {
      setLoading(false);
    }
  };
  usePageData(pageData);
  useEffect(() => {
    updated && fetchData();
    setUpdated(false);
  }, [updated]);
  const formik = useFormik({
    initialValues: {
      fullName: doctorProfile?.fullName || '',
      phoneNumber: doctorProfile?.phoneNumber || '',
      license: {
        number: doctorProfile?.license?.number || ''
      }
    },
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`
        };

        const formResponse = await updateData(
          `${updateDoctorProfileURL}/${role === 'admin' ? id : doctor_id}`,
          values,
          {
            headers
          }
        );

        if (selectedImage) {
          const formData = new FormData();
          formData.append('media', selectedImage);
          formData.append('doctorId', role === 'admin' ? id : doctor_id);

          try {
            let res2 = await axios.post(imguploaderUrl, formData, {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data'
              }
            });

            if (res2?.status === 201 || res2.status === 200) {
              message.success('User updated successfully');
              setUpdated(true);
            } else {
              message.error('Validation Error');
            }
          } catch (error: any) {
            const errorMessage = error.response?.data?.errors[0]?.message || 'Validation Error';
            message.error(errorMessage);
          }
        } else {
          if (formResponse?.status === 201 || formResponse.status === 200) {
            message.success('User updated successfully');
          } else {
            message.error('Validation Error');
          }
        }
      } catch (error: any) {
        const errorMessage = error.response?.data?.errors[0]?.message || 'Validation Error';
        message.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  });
  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Spin
            spinning={loading}
            size='large'
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
          />
        </div>
      ) : (
        doctorProfile && (
          <>
            <ImageLoader
              onSelectImage={(image) => setSelectedImage(image)}
              size={100}
              src={doctorProfile?.logoMediaId?.url}
            />
            <Form layout='vertical' onFinish={formik.handleSubmit}>
              <div className='row mt-4 '>
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='Name'>
                    <Input
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='fullName'
                      style={{ color: 'black' }}
                      placeholder='Full Name'
                    />
                  </FormItem>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='Phone Number'>
                    <Input
                      type='text'
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='phoneNumber'
                      style={{ color: 'black' }}
                      placeholder='Phone Number'
                      onKeyDown={(e) => {
                        // Allow: backspace, delete, tab, escape, enter
                        if (
                          [46, 8, 9, 27, 13].includes(e.keyCode) ||
                          // Allow: home, end, left, right
                          (e.keyCode >= 35 && e.keyCode <= 39)
                        ) {
                          // Let it happen, don't do anything
                          return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if (
                          (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                          (e.keyCode < 96 || e.keyCode > 105)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormItem>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='Email'>
                    <Input
                      disabled
                      style={{ color: 'black' }}
                      value={doctorProfile?.email}
                      placeholder='Email'
                    />
                  </FormItem>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='license'>
                    <Input
                      type='text'
                      pattern='[0-9]*'
                      inputMode='numeric'
                      value={formik?.values?.license?.number}
                      onChange={(e) => formik.setFieldValue('license.number', e.target.value)}
                      onBlur={formik.handleBlur}
                      name='license.number'
                      style={{ color: 'black' }}
                      placeholder='license'
                      onKeyDown={(e) => {
                        // Allow: backspace, delete, tab, escape, enter
                        if (
                          [46, 8, 9, 27, 13].includes(e.keyCode) ||
                          // Allow: home, end, left, right
                          (e.keyCode >= 35 && e.keyCode <= 39)
                        ) {
                          // Let it happen, don't do anything
                          return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if (
                          (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                          (e.keyCode < 96 || e.keyCode > 105)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                {/**<div className='col-md-6 col-sm-12'>
                  <FormItem label='Patients'>
                    <Input
                      disabled
                      style={{ color: 'black' }}
                      value={doctorProfile?.patients?.length}
                      placeholder='Patients'
                    />
                  </FormItem>
                </div>**/}
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='Account Status'>
                    <Input
                      value={doctorProfile?.accountStatus}
                      disabled
                      style={{ color: 'black' }}
                      placeholder='Account Status'
                    />
                  </FormItem>
                </div>
              </div>
              {/**<div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <FormItem label='Form Group'>
                    <Input
                      disabled
                      style={{ color: 'black' }}
                      value={doctorProfile?.category?.title}
                      placeholder='Form Group'
                    />
                  </FormItem>
                </div>
              </div>**/}
              <div className='d-flex justify-content-end'>
                <Button type='primary' htmlType='submit'>
                  Save Info
                </Button>
              </div>
            </Form>
          </>
        )
      )}
      {role === 'doctor' ? <EditAccountPage /> : ''}
    </>
  );
};

export default DoctorProfilePage;
