// const url = process.env.REACT_APP_BASE_URL;
// const url = 'http://localhost:3000/api/v1/';
const url = 'https://radiology-dev-myxac.ondigitalocean.app/api/v1/';
export const getAllSpecieURL = url + 'specie';
export const getAllOrganURL = url + 'organ';
export const getAllDiseaseURL = url + 'disease';
export const getAllPatientURL = url + 'patient';
export const getPatientByIdURL = url + 'patient/getByDoctor';
export const getAllFormURL = url + 'form';
export const getSingleFormURL = url + 'form/get/';
export const getSingleFormWithRangeValueURL = url + 'form/getFormData/';
export const getAllSectionURL = url + 'section';
export const getAllFieldURL = url + 'field';
export const getAllRangeURL = url + 'range';
export const getOrganBySpecieURL = getAllOrganURL + '/getBySpecie';
export const getAllUsersURL = url + 'auth/login';
export const getFormByOrganURL = getAllFormURL + '/getByOrganID/';
export const getAllFindingsUrl = url + 'finding';
export const getAllFindingCategoriesUrl = url + 'findingcategory';
export const getSingleFindingUrl = url + 'finding/getbyform/';
export const getAllReportURL = url + 'report';
export const reportMediaURL = url + 'report/addMedia';
export const getAllDoctorURL = url + 'doctor';
export const updateDoctorProfileURL = url + 'doctor/update';
export const getDashboardCountURL = url + 'auth/counter';
export const resetPasswordUrl = url + 'auth/reset';
export const getInActiveDoctorsUrl = url + 'doctor/archived';
export const activateDoctorUrl = url + 'doctor/activate';
export const registerDoctorUrl = url + 'doctor/register';
export const forgotPasswordUrl = url + 'auth/forgot';
export const updateAccountUrl = url + 'users/edit-account';
export const imguploaderUrl = url + 'users/update-logo';
export const formGroupUrl = url + 'category';
export const duplicateFormUrl = url + 'form/duplicateForm';
export const socketIoUrl = 'https://radiology-dev-myxac.ondigitalocean.app';
// export const socketIoUrl = 'http://localhost:5000';
